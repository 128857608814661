<template>
  <v-row
    class="mt-3 mb-2"
  >
    <v-col>
      <v-menu
        v-model="dateFromMenu"
        :nudge-right="isMobile || isTablet ? 0 : 40"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="dateFromFormatted"
            :label="$t('satellite-tracking/report.date_from')"
            prepend-icon="mdi-calendar"
            readonly
            hide-details
            v-bind="attrs"
            v-on="on"
            @blur="parseDmyDate(dateFromFormatted)"
          />
        </template>
        <v-date-picker
          v-model="dateFrom"
          first-day-of-week="1"
          :max="today"
          @input="dateFromMenu = false"
        />
      </v-menu>
    </v-col>
    <v-col>
      <v-menu
        v-model="dateToMenu"
        :close-on-content-click="false"
        :nudge-right="isMobile ? -70 : isTablet ? -30 : 40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="dateToFormatted"
            :label="$t('satellite-tracking/report.date_to')"
            prepend-icon="mdi-calendar"
            readonly
            hide-details
            v-bind="attrs"
            v-on="on"
            @blur="parseDmyDate(dateToFormatted)"
          />
        </template>
        <v-date-picker
          v-model="dateTo"
          :min="dateFrom"
          :max="maxToDate"
          first-day-of-week="1"
          @input="dateToMenu = false"
        />
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs'
import { formatSqlDate, parseDmyDate } from '@/global/services/helpers/dates'

export default {
  name: 'ReportDates',

  props: {
    dateRangeLimit: {
      type: Number,
      default: 31
    },

    dateMenus: {
      type: Object,
      default: () => ({})
    },

    resetDateTo: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    today: new Date().toISOString().slice(0, 10),
    dateFrom: null,
    dateTo: null,
    dateFromFormatted: null,
    dateToFormatted: null,
    dateFromMenu: false,
    dateToMenu: false,
    isMobile: false,
    isTablet: false
  }),

  computed: {
    maxToDate () {
      if (this.dateFrom && this.dateRangeLimit) {
        const maxDate = dayjs(this.dateFrom).add(this.dateRangeLimit, 'day')
        return maxDate.isAfter(this.today) ? this.today : maxDate.toISOString()
      }

      return this.today
    }
  },

  watch: {
    resetDateTo (resetDateTo) {
      if (resetDateTo) {
        this.dateToFormatted = null
        this.$emit('date-to-reset')
      }
    },

    dateFrom (dateFrom) {
      this.$emit('set-date-from', dateFrom)

      this.dateFromFormatted = formatSqlDate(dateFrom)
    },

    dateTo (dateTo) {
      this.$emit('set-date-to', dateTo)

      this.dateToFormatted = formatSqlDate(dateTo)
    },

    dateFromMenu (dateFromMenu) {
      const dateMenus = {
        dateFromMenu: dateFromMenu,
        dateToMenu: this.dateToMenu
      }

      this.$emit('date-menus', dateMenus)
    },

    dateToMenu (dateToMenu) {
      const dateMenus = {
        dateFromMenu: this.dateFromMenu,
        dateToMenu: dateToMenu
      }

      this.$emit('date-menus', dateMenus)
    },

    dateMenus (dateMenus) {
      this.dateFromMenu = dateMenus.dateFromMenu
      this.dateToMenu = dateMenus.dateToMenu
    }
  },

  created () {
    this.dateFrom = this.dateTo = this.today
  },

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  beforeDestroy () {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },

  methods: {
    parseDmyDate (date) {
      return parseDmyDate(date)
    },

    onResize () {
      this.isMobile = window.innerWidth <= 480
      this.isTablet = window.innerWidth > 480 && window.innerWidth <= 768
    }
  }
}
</script>
