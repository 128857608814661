<template>
  <div style="height: 450px;">
    <map-base
      ref="mapBase"
      :markers="markers"
      :mapClass="'mapReport'"
      :radiusValues="radiusValues"
      @handleClickEvent="handleMapClick"
      @handleMarkerDragEnd="onMarkerDragEnd"
    />
  </div>
</template>

<script>
import MapBase from '@/global/components/map/MapBase.vue'

export default {
  components: {
    MapBase
  },
  props: {
    markerRadius: {
      type: [Number, String],
      default: 50
    }
  },

  data () {
    return {
      markers: [],
      radiusValues: [],
      earthRadius: 6378137, // Earth radius in meters,
      timeoutId: 0
    }
  },

  watch: {
    markerRadius (newVal) {
      clearTimeout(this.timeoutId)
      if (this.radiusValues.length !== 0 && newVal !== '') {
        this.radiusValues[0].radius = Number(newVal)
        const that = this
        this.timeoutId = setTimeout(() => {
          that.centerMapOnMarker(this.radiusValues[0].coordinate, newVal)
        }, 200)
      }
    }
  },

  methods: {
    handleMapClick (e) {
      this.markers = [
        {
          coordinate: [e.latlng.lat, e.latlng.lng],
          draggable: true,
          radius: Number(this.markerRadius)
        }
      ]
      this.radiusValues = [
        {
          coordinate: [e.latlng.lat, e.latlng.lng],
          radius: Number(this.markerRadius),
          options: {
            color: '#3388ff',
            fillColor: '#3388ff',
            fillOpacity: 0.2
          }
        }
      ]
      this.centerMapOnMarker([e.latlng.lat, e.latlng.lng], this.markerRadius)
      const location = {
        latitude: e.latlng.lat,
        longitude: e.latlng.lng
      }
      this.$emit('location', location)
    },
    onMarkerDragEnd (e) {
      this.markers = [
        {
          coordinate: [e.target._latlng.lat, e.target._latlng.lng],
          draggable: true,
          radius: Number(this.markerRadius)
        }
      ]
      this.radiusValues = [
        {
          coordinate: [e.target._latlng.lat, e.target._latlng.lng],
          radius: Number(this.markerRadius),
          options: {
            color: '#3388ff',
            fillColor: '#3388ff',
            fillOpacity: 0.2
          }
        }
      ]
      this.centerMapOnMarker([e.target._latlng.lat, e.target._latlng.lng], this.markerRadius)
    },
    centerMapOnMarker (coordinates, radius) {
      const zoomLevel = Math.floor(Math.log2((this.earthRadius * 2 * Math.PI) / (radius * 2)))
      this.$refs?.mapBase?.$refs?.map?.mapObject?.setView(coordinates, zoomLevel)
    }
  }
}
</script>
