<template>
  <v-row id="report-wrapper">
    <v-col>
      <v-card>
        <v-tabs
          v-model="tab"
          height="40"
          grow
        >
          <v-tab
            v-for="vehicle in vehicles"
            :key="vehicle.id"
            class="py-3"
            @click="setDeviceIdToStore(vehicle.id)"
          >
            {{ vehicle.registration + ' (' + vehicle.label + ')' }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="vehicle in vehicles"
            :key="vehicle.id"
          >
            <single-device-report-table
              :key="vehicle.id"
              :fetched-report="getReportForVehicle(vehicle.id)"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SingleDeviceReportTable from './SingleDeviceReportTable'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapGetters } = createNamespacedHelpers('base/reportsByVehicle')

export default {
  name: 'MultipleDeviceReportTable',

  components: {
    SingleDeviceReportTable
  },

  props: {
    fetchedReports: {
      type: Object,
      required: true,
      default: () => ({})
    },

    vehicles: {
      type: [Array, null],
      required: true,
      default: () => ([])
    },

    reportType: {
      type: String,
      required: true,
      default: () => null
    }
  },

  data () {
    return {
      tab: null
    }
  },

  computed: {
    ...mapGetters(['getSelectedDeviceIdForReportFromVehicleMultiselect'])
  },

  methods: {
    ...mapActions(['setSelectedDeviceIdForReportFromVehicleMultiselect']),

    setDeviceIdToStore (vehicleId) {
      this.setSelectedDeviceIdForReportFromVehicleMultiselect(vehicleId)
    },

    getReportForVehicle (vehicleId) {
      // set initial vehicleId to vuex store
      if (!this.getSelectedDeviceIdForReportFromVehicleMultiselect) {
        this.setDeviceIdToStore(vehicleId)
      }
      if (this.fetchedReports && this.reportType !== 'temperatureReport') {
        return {
          ...this.fetchedReports,
          data: this.fetchedReports.data[vehicleId],
          summaryCol: this.fetchedReports.summaryCol[vehicleId]
        }
      }
      else if (this.reportType === 'temperatureReport') {
        return {
          ...this.fetchedReports,
          data: this.fetchedReports.data[vehicleId],
          sensorNames: this.fetchedReports.data.sensorNames
        }
      }
      return {}
    }
  }
}
</script>
