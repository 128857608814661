<template>
  <v-dialog
    v-model="showDialog"
    persistent
    :max-width="isMobile || isTablet ? '479px' : '600px'"
    @keydown.enter.prevent="enterButtonPressed"
    @keydown.esc.prevent="escapeButtonPressed"
  >
    <v-card>
      <v-card-title class="text-break">
        <span class="headline">
          {{ $t('satellite-tracking/report.choose_parameters_for_report') + ': "' + reportDetails.reportName + '"' }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row
            v-if="showCompanyScope && getReportsMetaData.change_company_scope"
            class="mb-3"
          >
            <v-col>
              <autocomplete
                v-if="!isEmpty(getReportsMetaData.change_company_scope)"
                v-model="companyScopeId"
                company-scope
                :label="getReportsMetaData.change_company_scope.label"
                :hide-details="true"
                :clearable="true"
                :items.sync="descendantCompanies"
                :options="getReportsMetaData.change_company_scope.autocomplete_options"
              />
            </v-col>
          </v-row>
          <!-- Dates -->
          <report-date-times
            v-if="reportDetails.useDateTime"
            :date-time-format="'DD.MM.YYYY HH:mm'"
            :reset-date-to="resetDateTo"
            :date-range-limit="dateRangeLimiter"
            @set-date-from="event => setDateFrom(event)"
            @set-date-to="event => setDateTo(event)"
            @date-to-reset="resetDateTo = false"
            @date-time-menus="event => setDateTimeMenus(event)"
          />
          <report-dates
            v-else
            :date-menus="dateMenus"
            :date-range-limit="dateRangeLimiter"
            @set-date-from="event => setDateFrom(event)"
            @set-date-to="event => setDateTo(event)"
            @date-menus="event => setDateMenus(event)"
          />
          <!-- ./ Dates -->
          <!-- Stops report range slider -->
          <v-row>
            <v-col>
              <v-range-slider
                v-if="reportDetails.reportType === 'stops'"
                v-model="stopsDurationRange"
                min="0"
                max="300"
                :label="$t('satellite-tracking/report.minutes')"
                inverse-label
                thumb-label="always"
                thumb-size="25px"
                hide-details
                class="mt-5"
              />
            </v-col>
          </v-row>
          <!-- ./ Stops report range slider -->
          <v-row v-if="reportDetails.reportType === 'lowVoltageLevel'">
            <v-col>
              <v-slider
                v-model="lowVoltageValue"
                min="9"
                max="30"
                :label="$t('satellite-tracking/report.volts')"
                hide-details
                inverse-label
                thumb-label="always"
                thumb-size="25px"
                class="mt-5"
              />
              <v-row
                class="justify-end"
                no-gutters
              >
                <small class="font-italic lighten-2">({{
                  $t('satellite-tracking/report.choose_max_voltage_level')
                }})</small>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="reportDetails.reportType === 'idleVehicleOperation' || reportDetails.reportType === 'speedingAbsolute'">
            <v-col>
              <v-slider
                v-model="idleVehicleMinTime"
                min="1"
                max="60"
                :label="$t('satellite-tracking/report.minutes')"
                hide-details
                inverse-label
                thumb-label="always"
                thumb-size="25px"
                class="mt-5"
              />
            </v-col>
          </v-row>
          <v-row v-if="reportDetails.reportType === 'speedingAbsolute'">
            <v-col>
              <v-slider
                v-model="maxSpeedValue"
                min="0"
                max="300"
                label="Km/h"
                hide-details
                inverse-label
                thumb-label="always"
                thumb-size="25px"
                class="mt-5"
              />
              <v-row
                class="justify-end"
                no-gutters
              >
                <small class="font-italic lighten-2">
                  ({{ $t('satellite-tracking/report.choose_max_speed') }})
                </small>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="reportDetails.reportType === 'speedingRoad'">
            <v-col>
              <v-slider
                v-model="speedLimitTolerance"
                min="0"
                max="100"
                label="Km/h"
                hide-details
                inverse-label
                thumb-label="always"
                thumb-size="25px"
                class="mt-5"
              />
              <v-row
                class="justify-end"
                no-gutters
              >
                <small class="font-italic lighten-2">
                  ({{ $t('satellite-tracking/alarm.speed_limit_tolerance') }})
                </small>
              </v-row>
            </v-col>
          </v-row>
          <!-- Temperature sensor report additional parameters -->
          <template v-if="isTempSensReportAndVehicleSelected()">
            <div v-if="getShowReferenceValues">
              <!-- Sensor sliders -->
              <v-row
                v-for="(sensorName, sensorKey) in getVehicleAssignedTempSensBetweenDates"
                :key="'slider_' + sensorName"
              >
                <v-col>
                  <v-range-slider
                    :value="getTemperatureRanges[sensorKey]"
                    min="-55"
                    max="110"
                    :label="$t('satellite-tracking/temperature_sensor.celsius')"
                    inverse-label
                    :hint="sensorName.toString()"
                    persistent-hint
                    thumb-label="always"
                    thumb-size="25px"
                    class="mt-5"
                    @input="setSliderRange(sensorKey, $event)"
                  />
                  <v-row
                    class="justify-end"
                    no-gutters
                  />
                </v-col>
              </v-row>
              <!-- ./ Sensor sliders -->
            </div>
            <!-- Toggle switches ignore geolocations and loaded vehicle -->
            <v-switch
              v-model="ignoreMainLocations"
              class="mb-5"
              hide-details
            >
              <template #label>
                {{ $t('satellite-tracking/report.ignore_main_locations') }}
              </template>
            </v-switch>
            <v-switch
              v-model="ignoreUnloadedVehicle"
              class="mb-5"
              hide-details
            >
              <template #label>
                {{ $t('satellite-tracking/report.ignore_unloaded_vehicle') }}
              </template>
            </v-switch>
            <!-- ./ Toggle switches ignore geolocations and loaded vehicle -->
            <!-- Reference value toggle switch -->
            <v-switch
              :input-value="getShowReferenceValues"
              class="mb-5"
              hide-details
              @change="setShowReferenceValues"
            >
              <template #label>
                {{ $t('satellite-tracking/temperature_sensor.show_reference_values') }}
              </template>
            </v-switch>
            <!-- Reference value toggle switch -->
          </template>
          <!-- ./ Temperature sensor report additional parameters -->
          <!-- Vehicle pickers -->
          <v-row>
            <v-col>
              <vehicle-picker
                v-model="selectedVehicles"
                :max-select="reportDetails.vehicleSelectionLimit"
                :scope-id="companyScopeId"
                :show-vehicles-with-assignments="true"
                :work-machines-only="reportDetails.reportType === 'machineOperation'"
                :use-old-logic="false"
              />
            </v-col>
          </v-row>
          <!-- ./ Vehicle pickers -->

          <!-- Vehicle sensors report sensor additional parameters -->
          <template v-if="this.selectedSensor.cleaning_width_input">
            <v-col>
              <v-text-field
                v-model="cleaningWidth"
                :label="$t('satellite-tracking/report.cleaning_width')"
                type="number"
                min="0"
                max="999999"
              />
            </v-col>
          </template>
          <template v-if="isVehicleSensorsReportAndVehicleSelected()">
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="selectedSensor"
                  outlined
                  dense
                  hide-details
                  :items="getVehicleAssignedSensorsBetweenDates"
                  :label="$t('satellite-tracking/report.select_sensor')"
                  item-value="id"
                  item-text="name"
                  return-object
                  class="pr-2"
                  @change="setSelectedSensor($event)"
                />
              </v-col>
            </v-row>
          </template>
          <!-- ./Vehicle sensors report sensor additional parameters -->

          <v-row v-if="reportDetails.reportType === 'geofence' || reportDetails.reportType === 'throughLocationReport'">
            <v-col
              cols="12"
              class="pb-0 mt-3"
            >
              <p
                class="mb-2 font-weight-medium"
                :style="{ fontSize: '16px' }"
              >
                {{ $t('satellite-tracking/report.retention_time') }}
              </p>
            </v-col>
            <v-col class="pt-0">
              <v-text-field
                v-model="fromMinutes"
                :label="$t('base.from')"
                type="number"
                min="0"
                max="999999"
              />
            </v-col>
            <v-col class="pt-0">
              <v-text-field
                v-model="toMinutes"
                :label="$t('base.to')"
                type="number"
                :min="fromMinutes"
                max="999999"
              />
            </v-col>
            <v-col
              v-if="reportDetails.reportType === 'throughLocationReport'"
              cols="12"
              class="pb-0 mt-3"
            >
              <p
                class="mb-2 font-weight-medium"
                :style="{ fontSize: '16px' }"
              >
                {{ $t('satellite-tracking/report.radius') }}
              </p>
            </v-col>
            <v-col
              v-if="reportDetails.reportType === 'throughLocationReport'"
              class="pt-0"
            >
              <v-text-field
                v-model="radius"
                type="number"
                min="0"
                max="999999"
              />
            </v-col>
          </v-row>
          <v-row v-if="reportDetails.reportType === 'throughLocationReport'">
            <v-col>
              <map-report
                @location="handleLocationSelected"
                :markerRadius=radius
              />
            </v-col>
          </v-row>
          <v-row v-if="reportDetails.reportType === 'geofence'">
            <v-col>
              <location-picker
                key="multipleLocationSelect"
                v-model="selectedLocations"
                :scope-id="companyScopeId"
                :refresh-locations="refreshLocations"
                @reset-refresh-locations="refreshLocations = false"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          @click="$emit('close', reportDetails.reportType)"
        >
          {{ $t('satellite-tracking/report.close') }}
        </v-btn>
        <v-btn
          small
          color="primary"
          @click="getReport"
        >
          {{ $t('satellite-tracking/report.show_report') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import withoutWatchersMixin from '@/global/mixins/withoutWatchersMixin'
import { formatSqlDate, parseDmyDate } from '@/global/services/helpers/dates'
import VehiclePicker from '@/modules/satellite-tracking-module/vehicle-picker/VehiclePicker'
import LocationPicker from '@/modules/satellite-tracking-module/location-picker/LocationPicker'
import Autocomplete from '@/modules/base-module/autocomplete/components/Autocomplete'
import ReportDates from '@/modules/base-module/reports/report-by-person/components/ReportDates'
import ReportDateTimes from '@/modules/base-module/reports/report-by-person/components/ReportDateTimes'
import MapReport from '@/modules/locations-module/locations/components/MapReport'

const {
  mapGetters: mapGettersConfig,
  mapActions: mapActionsConfig
} = createNamespacedHelpers('base/config')

const {
  mapGetters: mapGettersReports,
  mapActions: mapActionsReports
} = createNamespacedHelpers('base/reportsByVehicle')

export default {
  name: 'ReportParametersDialog',

  components: {
    Autocomplete,
    LocationPicker,
    ReportDates,
    ReportDateTimes,
    VehiclePicker,
    MapReport
  },

  mixins: [withoutWatchersMixin],

  props: {
    dialog: {
      type: Boolean,
      required: true
    },

    reportDetails: {
      type: Object,
      required: true,
      default: () => ({
        reportName: null,
        reportType: null
      })
    }
  },

  data () {
    return {
      isMobile: false,
      isTablet: false,
      dateFrom: null,
      dateFromFormatted: null,
      dateMenus: {
        dateFrom: false,
        dateTo: false
      },
      resetDateTo: false,
      dateTo: null,
      dateToFormatted: null,
      dateTimeMenus: {
        dateFromMenu: false,
        dateToMenu: false
      },
      stopsDurationRange: [15, 60],
      lowVoltageValue: 10,
      idleVehicleMinTime: 3,
      maxSpeedValue: 100,
      speedLimitTolerance: 30,
      fromMinutes: 3,
      toMinutes: 15,
      radius: 50,
      cleaningWidth: 0.0,
      companyScopeId: null,
      descendantCompanies: [],
      refreshLocations: false,
      ignoreMainLocations: false,
      ignoreUnloadedVehicle: false,
      latitude: null,
      longitude: null,
      selectedSensor: {
        id: null,
        type: null,
        name: null,
        cleaning_width_input: null
      }
    }
  },

  computed: {
    ...mapGettersReports([
      'fetchedReport',
      'getReportsMetaData',
      'getSelectedVehicles',
      'getSelectedLocations',
      'getVehicleAssignedTempSensBetweenDates',
      'getVehicleAssignedSensorsBetweenDates',
      'getTemperatureRanges',
      'getShowReferenceValues'
    ]),

    ...mapGettersConfig({
      globalCompanyScope: 'companyScope',
      isCompanyScopeUpdated: 'isCompanyScopeUpdated'
    }),

    dateRangeLimiter () {
      return this.reportDetails.dateRangeLimit
    },

    showCompanyScope () {
      if (this.globalCompanyScope) {
        return false
      }

      return true
    },

    showDialog () {
      return this.dialog
    },

    selectedVehicles: {
      get () {
        return this.getSelectedVehicles
      },
      set (vehicles) {
        this.setSelectedVehicles(vehicles)
      }
    },

    selectedLocations: {
      get () {
        return this.getSelectedLocations
      },
      set (locations) {
        this.setSelectedLocations(locations)
      }
    },

    maxToDate () {
      if (this.dateRangeLimiter) {
        return dayjs(this.dateFrom).add(this.dateRangeLimiter, 'day').format('YYYY-MM-DD')
      }

      return dayjs()
    }
  },

  watch: {
    dateFrom (newDate) {
      if (this.dateRangeLimiter) {
        this.dateFromFormatted = formatSqlDate(newDate)
        this.setDateFromFormattedToStore(this.dateFromFormatted)
        const from = dayjs(newDate)
        const to = dayjs(this.dateTo)

        if (from.add(this.dateRangeLimiter, 'day').isBefore(to)) {
          this.$withoutWatchers(() => {
            this.dateTo = null
            this.dateToFormatted = null
          })
          this.resetDateTo = true
        }
      }

      this.conditionallyFetchAssignments()
    },

    dateTo (newDate) {
      this.dateToFormatted = formatSqlDate(newDate)
      this.setDateToFormattedToStore(this.dateToFormatted)
      this.conditionallyFetchAssignments()
    },

    selectedVehicles () {
      this.conditionallyFetchAssignments()
    },

    async companyScopeId () {
      this.selectedVehicles = []

      await this.fetchReportsMetaData({
        company_scope_id: this.companyScopeId
      })
    },

    async isCompanyScopeUpdated () {
      this.selectedVehicles = []

      await this.fetchReportsMetaData({
        company_scope_id: null
      })

      this.unsetCompanyScopeUpdated()
    },

    globalCompanyScope () {
      this.refreshLocations = true
    }
  },

  created () {
    this.dateFrom = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    this.dateFromFormatted = formatSqlDate(this.dateFrom)
    this.dateTo = dayjs().format('YYYY-MM-DD')
    this.dateToFormatted = formatSqlDate(this.dateTo)
  },

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  beforeDestroy () {
    this.resetState()

    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },

  methods: {
    ...mapActionsReports([
      'resetState',
      'fetchReport',
      'fetchReportsMetaData',
      'setReportParams',
      'setTemperatureRange',
      'setSelectedVehicles',
      'setSelectedDateFrom',
      'setSelectedDateTo',
      'setSelectedLocations',
      'fetchVehicleAssignedTempSensBetweenDates',
      'fetchVehicleAssignedSensorsBetweenDates',
      'setShowReferenceValues'
    ]),

    ...mapActionsConfig(['unsetCompanyScopeUpdated']),

    isEmpty,
    parseDmyDate,
    handleLocationSelected ({ latitude, longitude }) {
      this.latitude = latitude
      this.longitude = longitude
    },

    isTempSensReportAndVehicleSelected () {
      return this.reportDetails.reportType === 'temperatureReport' &&
        Object.keys(this.getVehicleAssignedTempSensBetweenDates).length > 0
    },

    isVehicleSensorsReportAndVehicleSelected () {
      return this.reportDetails.reportType === 'vehicleSensors' &&
        Object.keys(this.getVehicleAssignedSensorsBetweenDates).length > 0
    },

    conditionallyFetchAssignments () {
      if (this.reportDetails.reportType === 'temperatureReport') {
        this.fetchVehicleAssignedTempSensBetweenDates()
      }
      if (this.reportDetails.reportType === 'vehicleSensors') {
        this.fetchVehicleAssignedSensorsBetweenDates()
      }
    },

    setDateFromFormattedToStore (date) {
      this.setSelectedDateFrom(date)
    },

    setDateToFormattedToStore (date) {
      this.setSelectedDateTo(date)
    },

    getReport () {
      if (this.areValidReportQueryParams()) {
        alert(this.$t('satellite-tracking/report.you_should_choose_all_parameters'))
      }
      else {
        this.setReportParams(this.getParams())
        this.fetchReport()
      }
    },

    areValidReportQueryParams () {
      return !this.dateFrom ||
        !this.dateTo ||
        !this.selectedVehicles.length ||
        (
          this.reportDetails.reportType === 'geofence' && (!this.selectedLocations || !this.selectedLocations.length)
        ) ||
        (
          this.reportDetails.reportType === 'throughLocationReport' && (!this.latitude || !this.longitude)
        ) ||
        (
          this.reportDetails.reportType === 'vehicleSensors' && (!this.selectedSensor.id)
        )
    },

    getParams () {
      this.$emit('close', this.reportDetails.reportType)
      return {
        reportType: this.reportDetails.reportType,
        vehicleIds: this.getVehicleIds(),
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        stopsDurationRange: this.stopsDurationRange,
        lowVoltageValue: this.lowVoltageValue,
        idleVehicleMinTime: this.idleVehicleMinTime,
        maxSpeedValue: this.maxSpeedValue,
        speedLimitTolerance: this.speedLimitTolerance,
        fromMinutes: this.fromMinutes,
        toMinutes: this.toMinutes,
        cleaningWidth: this.cleaningWidth,
        radius: this.radius,
        locationIds: this.getLocationIds(),
        company_scope_id: this.companyScopeId,
        temperatureRanges: this.getTemperatureRanges,
        ignoreMainLocations: this.ignoreMainLocations,
        ignoreUnloadedVehicle: this.ignoreUnloadedVehicle,
        showReferenceValues: this.getShowReferenceValues,
        latitude: this.latitude,
        longitude: this.longitude,
        selectedSensor: this.selectedSensor
      }
    },

    getLocationIds () {
      if (this.getSelectedLocations) {
        return this.getSelectedLocations.map(location => location.id)
      }
      return []
    },

    getVehicleIds () {
      return this.getSelectedVehicles.map(vehicle => vehicle.id)
    },

    setDateFrom (event) {
      this.dateFrom = event
    },

    setDateTo (event) {
      this.dateTo = event
    },

    setDateMenus (event) {
      this.dateMenus = event
    },

    setSelectedSensor (event) {
      this.selectedSensor = event
    },
    setDateTimeMenus (event) {
      this.dateTimeMenus = event
    },

    enterButtonPressed () {
      if (this.reportDetails.useDateTime) {
        const someDateTimePickerIsOpen = Object.values(this.dateTimeMenus).includes(true)
        someDateTimePickerIsOpen ? this.resetDateTimeMenus() : this.getReport()
      }
      else {
        const someDatePickerIsOpen = Object.values(this.dateMenus).includes(true)
        someDatePickerIsOpen ? this.resetDateMenus() : this.getReport()
      }
    },

    escapeButtonPressed () {
      if (this.reportDetails.useDateTime) {
        const someDateTimePickerIsOpen = Object.values(this.dateTimeMenus).includes(true)
        if (!someDateTimePickerIsOpen) {
          this.$emit('close', this.reportDetails.reportType)
        }
      }
      else {
        const someDatePickerIsOpen = Object.values(this.dateMenus).includes(true)
        if (!someDatePickerIsOpen) {
          this.$emit('close', this.reportDetails.reportType)
        }
      }
    },

    resetDateMenus () {
      this.dateMenus = {
        dateFromMenu: false,
        dateToMenu: false
      }
    },

    resetDateTimeMenus () {
      this.dateTimeMenus = {
        dateFromMenu: false,
        dateToMenu: false
      }
    },

    onResize () {
      this.isMobile = window.innerWidth <= 480
      this.isTablet = window.innerWidth > 480 && window.innerWidth <= 768
    },

    setSliderRange (sensorKey, tempRange) {
      this.setTemperatureRange({ key: sensorKey, value: tempRange })
    }
  }
}
</script>
