<template>
  <v-row class="mt-3 mb-2">
    <v-col>
      <v-menu
        v-model="dateFromMenu"
        transition="slide-y-transition"
        bottom
        :close-on-content-click="false"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="dateFromFormatted"
            v-bind="attrs"
            :label="$t('satellite-tracking/report.date_from')"
            prepend-icon="mdi-calendar"
            readonly
            hide-details
            v-on="on"
            @blur="parseDmyDateTime(dateFromFormatted)"
          />
        </template>
        <datetime-picker
          v-model="dateFrom"
          :emit-default-time="true"
          :default-time="'00:00:00'"
          :max-date="today"
          :reset-tabs="resetDateFromTabs"
          @close-picker="closeDateFromPicker"
          @escape-enter-button-pressed="emitEnterEscapePressEvent"
          :on-double-click="closeDateFromPicker"
        >
          <v-col class="white text-right pb-2 px-2">
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="closeDateFromPicker"
            >
              {{ $t('base.ok') }}
            </v-btn>
          </v-col>
        </datetime-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-menu
        v-model="dateToMenu"
        transition="slide-y-transition"
        bottom
        :close-on-content-click="false"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="dateToFormatted"
            v-bind="attrs"
            :label="$t('satellite-tracking/report.date_from')"
            prepend-icon="mdi-calendar"
            readonly
            hide-details
            v-on="on"
            @blur="parseDmyDateTime(dateToFormatted)"
          />
        </template>
        <datetime-picker
          v-model="dateTo"
          :emit-default-time="true"
          :default-time="defaultToTime"
          :min-date="minDate"
          :max-date="maxToDate"
          :max-time="maxToTime"
          :reset-tabs="resetDateToTabs"
          @escape-enter-button-pressed="emitEnterEscapePressEvent"
          @close-picker="closeDateToPicker"
          :on-double-click="closeDateToPicker"
        >
          <v-col class="white text-right pb-2 px-2">
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="closeDateToPicker"
            >
              {{ $t('base.ok') }}
            </v-btn>
          </v-col>
        </datetime-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import DatetimePicker from '@/global/components/DatetimePicker.vue'
import { formatSqlDateTime, parseDmyDateTime } from '@/global/services/helpers/dates'
import dayjs from 'dayjs'

const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

export default {
  name: 'ReportDateTimes',

  components: {
    DatetimePicker
  },

  props: {
    dateTimeFormat: {
      type: String,
      default: 'DD.MM.YYYY HH:mm:ss'
    },

    resetDateTo: {
      type: Boolean,
      default: false
    },

    dateRangeLimit: {
      type: Number,
      default: 31
    }
  },

  data: () => ({
    today: new Date().toISOString().slice(0, 10),
    dateFrom: null,
    dateTo: null,
    dateFromFormatted: null,
    dateToFormatted: null,
    dateFromMenu: false,
    dateToMenu: false,
    resetDateFromTabs: false,
    resetDateToTabs: false
  }),

  computed: {
    defaultToTime () {
      return '23:59:59'
    },

    minDate () {
      return dayjs(this.dateFrom).format('YYYY-MM-DD')
    },

    maxToDate () {
      if (this.dateFrom && this.dateRangeLimit) {
        const maxDate = dayjs(this.dateFrom).add(this.dateRangeLimit, 'day')
        return maxDate.isAfter(this.today) ? this.today : maxDate.toISOString()
      }

      return this.today
    },

    maxToTime () {
      if (dayjs(this.dateTo).isToday()) return dayjs().format('HH:mm')

      return this.defaultToTime
    }
  },

  watch: {
    dateFrom (dateFrom) {
      this.$emit('set-date-from', dateFrom)

      if (this.dateTo) {
        const dayToDayjs = dayjs(this.dateTo)
        const dateFromDayjs = dayjs(dateFrom)
        if (dayToDayjs.isBefore(dateFrom) || (this.dateRangeLimit && dateFromDayjs.isBefore(dayToDayjs.add(-this.dateRangeLimit, 'day')))) {
          this.$emit('set-date-to', null)
          this.dateToFormatted = null
        }
      }

      this.dateFromFormatted = formatSqlDateTime(dateFrom, this.dateTimeFormat)
    },

    dateTo (dateTo) {
      this.$emit('set-date-to', dateTo)

      if (dateTo) {
        this.dateToFormatted = formatSqlDateTime(dateTo, this.dateTimeFormat)
      }
      else {
        this.dateToFormatted = null
      }
    },

    resetDateTo (resetDateTo) {
      if (resetDateTo) {
        this.dateToFormatted = null
        this.$emit('date-to-reset')
      }
    },

    dateFromMenu (dateFromMenu) {
      this.resetDateFromTabs = !this.resetDateFromTabs

      const dateTimeMenus = {
        dateFromMenu: dateFromMenu,
        dateToMenu: this.dateToMenu
      }

      this.$emit('date-time-menus', dateTimeMenus)
    },

    dateToMenu (dateToMenu) {
      this.resetDateToTabs = !this.resetDateToTabs

      const dateTimeMenus = {
        dateFromMenu: this.dateFromMenu,
        dateToMenu: dateToMenu
      }

      this.$emit('date-time-menus', dateTimeMenus)
    }
  },

  created () {
    this.dateFrom = dayjs().startOf('day').toISOString()
    this.dateTo = dayjs().toISOString()
  },

  methods: {
    emitEnterEscapePressEvent () {
      this.$emit('escape-enter-button-pressed')
    },

    closeDateFromPicker () {
      this.dateFromMenu = false
    },

    closeDateToPicker () {
      this.dateToMenu = false
    },

    parseDmyDateTime (date) {
      return parseDmyDateTime(date)
    }
  }
}
</script>
