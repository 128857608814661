<template>
  <div
    class="ml-2 d-flex align-center"
  >
    <v-btn
      v-show="!showExportFileFormatSelect"
      small
      color="primary"
      @click="selectReportExportFile"
    >
      {{ $t('satellite-tracking/report.file_export') }}
      <v-icon
        dark
        right
        class="ml-1"
      >
        mdi-file-export-outline
      </v-icon>
    </v-btn>
    <v-select
      v-show="showExportFileFormatSelect"
      v-model="selectedExportFileFormat"
      :items="fileFormats"
      :disabled="loading"
      :error="validationErrorPresent"
      :label="$t('satellite-tracking/report.file_format')"
      class="ml-2 my-1"
      hide-details
      dense
      @input="validationErrorPresent = false"
    />
    <div
      v-if="selectedExportFileFormat === 'pdf'"
      class="orientation"
    >
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-show="showExportFileFormatSelect"
            small
            class="ml-2 my-1"
            :color="pdfOrientation === 'portrait' ? 'primary' : ''"
            :disabled="loading"
            v-bind="attrs"
            v-on="on"
            @click="setPdfOrientation('portrait')"
          >
            <v-icon>
              mdi-crop-portrait
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('satellite-tracking/report.portrait_orientation') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-show="showExportFileFormatSelect"
            small
            :color="pdfOrientation === 'landscape' ? 'primary' : ''"
            :disabled="loading"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            @click="setPdfOrientation('landscape')"
          >
            <v-icon>
              mdi-crop-landscape
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('satellite-tracking/report.landscape_orientation') }}</span>
      </v-tooltip>
    </div>
    <v-btn
      v-show="showExportFileFormatSelect"
      small
      color="secondary"
      class="ml-2"
      :disabled="loading"
      @click="resetFileExport"
    >
      {{ $t('base.cancel') }}
    </v-btn>
    <v-btn
      v-show="showExportFileFormatSelect"
      class="ml-2"
      small
      color="primary"
      :disabled="loading"
      :loading="loading"
      @click="downloadReport"
    >
      <v-icon>
        mdi-download
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'DownloadReportButton',

  props: {
    route: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      selectedExportFileFormat: null,
      showExportFileFormatSelect: false,
      validationErrorPresent: false,
      fileFormats: ['csv', 'pdf', 'xlsx'],
      fileFormatDownloadConfig: {
        csv: { type: 'text/csv;charset=utf-8;' },
        pdf: { type: 'application/pdf' },
        xlsx: { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
      },
      pdfOrientation: 'portrait',
      loading: false
    }
  },

  methods: {
    selectReportExportFile () {
      this.showExportFileFormatSelect = true
    },

    resetFileExport () {
      this.showExportFileFormatSelect = false
      this.selectedExportFileFormat = null
      this.validationErrorPresent = false
      this.pdfOrientation = 'portrait'
    },

    downloadReport () {
      if (!this.selectedExportFileFormat) {
        this.validationErrorPresent = true
        return
      }

      this.loading = true

      const url = `/api/satellite-tracking/${this.route}`

      return fetch(url, {
        method: 'POST',
        body: JSON.stringify(this.getExportParams()),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (!res.ok) {
            const responseStatusText = res.statusText
            const errorMessage = `${responseStatusText}`
            throw new Error(errorMessage)
          }

          return res.arrayBuffer()
        })
        .then(arrayBuffer => {
          // BE endpoint sends a readable stream of bytes
          const byteArray = new Uint8Array(arrayBuffer)
          const link = window.document.createElement('a')
          link.href = window.URL.createObjectURL(
            new Blob([byteArray], this.fileFormatDownloadConfig[this.selectedExportFileFormat])
          )
          link.download = `${this.fileName}.${this.selectedExportFileFormat}`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(error => {
          throw new Error('Error occurred:' + error)
        })
        .finally(() => {
          this.resetFileExport()
          this.loading = false
        })
    },

    getExportParams () {
      const params = { ...this.data }

      params.fileFormat = this.selectedExportFileFormat

      if (this.selectedExportFileFormat === 'pdf') {
        params.pdfOrientation = this.pdfOrientation
      }

      return params
    },

    setPdfOrientation (pdfOrientation) {
      this.pdfOrientation = pdfOrientation
    }
  }
}
</script>

<style scoped lang="scss">
.orientation {
  display: flex;
  flex-direction: column;
}
</style>
