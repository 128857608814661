<!--
-- This component generates a modal that displays poly-lines on a Leaflet map. It is globally accessible and can be customized if necessary.
-- Key props include: 'dialog' (which manages the visibility of this component), 'routeData' (data utilized for polyline creation), and 'dialogTitle' (the modal's title).
-- This modal serves as a globally accessible component, allowing for its use from any location.
-->
<template>
  <div class="d-inline mr-4">
    <v-dialog
      v-model="dialog"
      @click:outside="closeDialog"
      @keydown.esc="closeDialog"
      max-width="800px"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <span class="headline">
            {{ $t(dialogTitle) }}
          </span>
          <v-spacer />
          <v-btn
            fab
            x-small
            class="pa-0 dialog-close-button no-background-hover"
            style="background: transparent;"
            elevation="0"
            @click="closeDialog"
          >
            <v-icon
              color="grey darken-2"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="margin: 0; padding: 0; border-top: 2px solid lightslategray; border-bottom: 2px solid lightslategray;">
          <v-container style="margin: 0; padding: 0;">
            <v-row style="margin: 0; padding: 0;">
              <v-col
                cols="12"
                style="margin: 0; padding: 0;"
              >
                <div style="height: 600px; margin: 0; padding: 0;"
                >
                  <map-base
                    ref="mapBase"
                    :map-options="{ doubleClickZoom: false }"
                    @set-position-marker-radius="radius => setPositionMarkerRadius(radius)"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="grey lighten-2">
          <v-spacer />
          <v-btn
            color="blue-grey darken-1"
            dark
            @click="closeDialog"
          >
            {{ $t('base.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import L from 'leaflet'
import colors from 'vuetify/lib/util/colors'
import MapBase from '@/global/components/map/MapBase'
import { api } from '@/global/services/api'
import { formatSqlDateTime } from '@/global/services/helpers/dates'
import { isEmpty } from 'lodash'

export default {
  name: 'MapRouteOverview',

  components: {
    MapBase
  },

  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    routeData: {
      type: [Array, Object],
      default: () => ([])
    },
    circleMarker: {
      type: [Array, null],
      default: null
    },
    tooltip: {
      type: [String, null],
      default: null
    },
    markerRadius: {
      type: Number,
      default: 50
    },
    vehicleId: {
      type: [Number, null],
      default: null
    },
    dialogTitle: {
      type: String,
      default: 'satellite-tracking.show_route_label'
    }
  },

  data () {
    return {
      dialog: false,
      icons: {
        startPoint: L.divIcon({
          className: 'l-marker-icon-route-start marker-icon',
          iconSize: [15, 15]
        }),
        endPoint: L.divIcon({
          className: 'l-marker-icon-route-end marker-icon',
          iconSize: [15, 15]
        }),
        midPoint: L.divIcon({
          className: 'l-marker-icon-route-mid marker-icon',
          iconSize: [8, 8]
        })
      }
    }
  },

  emits: ['close-dialog'],

  watch: {
    showDialog: function (showDialog) {
      this.dialog = showDialog
      if (showDialog && this.circleMarker) this.zoomAndCenterMap()
    },

    marker: function (value) {
      if (value) {
        this.zoomAndCenterMap()
      }
    },

    routeData (value) {
      // nextTick is needed because of map lazy loading
      this.$nextTick(() => {
        const mappedRouteData = Array.isArray(value)
          ? value.map(position => {
            if (position && position.latitude && position.longitude) {
              const latLng = L.latLng(position.latitude, position.longitude)
              return Object.assign(latLng, position)
            }
          })
          : []
        if (mappedRouteData && !mappedRouteData.every(value => value == null)) {
          const polylineConfig = {
            polylines: [],
            markers: []
          }

          // Add polyline
          polylineConfig.polylines.push({
            coordinates: mappedRouteData,
            options: {
              id: 'overspeed-route',
              color: colors.blue.base,
              weight: 10
            }
          })
          // Add both start and end point
          polylineConfig.markers.push({
            coordinates: mappedRouteData[0],
            radius: {
              coordinates: this.circleMarker,
              radius: this.markerRadius,
              popup: {
                popupData: () => this.getCircleTooltipData()
              }
            },
            options: {
              icon: this.icons.startPoint,
              id: 'overspeed-route_startMarker'
            }
          },
          {
            coordinates: mappedRouteData.slice(-1)[0],
            options: {
              icon: this.icons.endPoint,
              id: 'overspeed-route_endMarker'
            }
          })

          polylineConfig.markers.forEach(marker => {
            marker.popup = {
              popupData: async () => await this.getMarkersTooltipData(marker, this.vehicleId)
            }
          })

          // Add mid-rout points
          const midRoutePoints = mappedRouteData.slice(1, -1)
          midRoutePoints.forEach(point => {
            polylineConfig.markers.push({
              type: 'circle',
              coordinates: point,
              popup: {
                popupData: async () => await this.getMidPositionData(point)
              },
              options: {
                icon: this.icons.midPoint,
                id: 'overspeed-route_midPoint',
                color: '#0D47A1',
                fillColor: '#fff',
                fillOpacity: '1',
                pane: 'markerPane'
              }
            })
          })
          if (polylineConfig.polylines.length) {
            this.$refs?.mapBase?.generatePolylines(polylineConfig)
          }
        }
      })
    }
  },

  methods: {
    formatSqlDateTime,

    closeDialog () {
      this.$emit('close-dialog')
    },

    setPositionMarkerRadius (radius) {
      // Change radius to all currently visible circle markers on map
      this.$refs?.mapBase?.changeAllCircleMarkersRadius(radius)
    },

    async getMarkersTooltipData (marker, vehicleId) {
      try {
        const params = {
          ...marker.coordinates,
          vehicleId: vehicleId || null
        }
        let routePositionText = `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
        let routeTime = ''
        let address = marker && marker.coordinates && marker.coordinates.address ? marker.coordinates.address : ''

        if (marker.options.id.toString().includes('startMarker')) {
          routePositionText = this.$t('satellite-tracking/map.route_start')
          routeTime = marker && marker.coordinates && marker.coordinates.date_time ? `<b>${this.$t('satellite-tracking/report.start_time')}:</b> ${this.formatSqlDateTime(marker.coordinates.date_time)}` : ''
        }
        else if (marker.options.id.toString().includes('endMarker')) {
          routePositionText = this.$t('satellite-tracking/map.route_end')
          routeTime = marker && marker.coordinates && marker.coordinates.date_time ? `<b>${this.$t('satellite-tracking/tracking-history.end_time')}:</b> ${this.formatSqlDateTime(marker.coordinates.date_time)}` : ''
        }

        // Do geo reverse only if address not provided
        if (!address) {
          const response = await api()['satellite-tracking'].get('reverse-geocode', params)
          address = response.data.address
        }

        let tooltipContent = `<div style="text-align: center; justify-content: center; font-weight: bold;">${routePositionText}</div>`

        if (routeTime && routeTime.trim() !== '') {
          tooltipContent += `${routeTime} <br>`
        }
        tooltipContent += `<b>${this.$t('satellite-tracking/history.pause_address')}:</b> ${address}`

        return tooltipContent
      }
      catch (e) {
        console.log('Error while getting route marker tooltip data in vehicle report, passing through location', {
          error: e,
          marker: marker
        })
        return `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
      }
    },

    getCircleTooltipData () {
      return this.tooltip && this.tooltip.toString().trim() !== '' ? `<b>${this.$t('satellite-tracking/report.retention_duration')}:</b> ${this.tooltip}` : `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
    },

    async getMidPositionData (point) {
      if (!point || isEmpty(point)) return `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`

      const dateTime = point.date_time ? point.date_time : null
      const lat = point.latitude ? point.latitude : null
      const lng = point.longitude ? point.longitude : null
      let result = ''

      if (dateTime && dateTime.toString().trim() !== '') {
        result += `<b>${this.$t('road-maintenance/patrol-service.date_and_time')}:</b> ${this.formatSqlDateTime(dateTime)}<br>`
      }
      if (lat && lng) {
        const response = await api()['satellite-tracking'].get('reverse-geocode', { lat: lat, lng: lng })
        if (response && response.data && response.data.address) {
          result += `<b>${this.$t('satellite-tracking/history.pause_address')}:</b> ${response.data.address}`
        }
      }

      return result.trim() !== '' ? result : `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
    },

    zoomAndCenterMap () {
      // timeout is needed because of v-lazy usage
      setTimeout(() => {
        this.$refs?.mapBase?.$refs?.map?.mapObject?.setView(this.circleMarker, 17)
      }, 150)
    }
  }
}
</script>

<style lang="scss">
.route-overview-wrapper {
  height: 700px;
  max-height: 100%;
}

.dialog-close-button {
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  z-index: 1000;
}
.l-marker-icon-route-start {
  background: url("/img/icons/icon_route_begin.png") center / contain;
}

.l-marker-icon-route-end {
  background: url("/img/icons/icon_route_end.png") center / contain;
}

.marker-icon {
  z-index: 201 !important;
}
</style>
