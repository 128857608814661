<template>
  <line-chart
    v-if="chartData"
    ref="chartRef"
    :chart-data="chartData"
    :options="chartOptions"
  />
</template>

<script>
import parseISO from 'date-fns/parseISO'
import { Chart, registerables } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { LineChart } from 'vue-chart-3'
import zoomPlugin from 'chartjs-plugin-zoom'
import { ref, computed, defineComponent } from '@vue/composition-api'

Chart.register(...registerables, zoomPlugin)

export default defineComponent({
  name: 'ReportChartBuilder',

  components: { LineChart },

  props: {
    fetchedReport: {
      type: Object,
      default: () => ({})
    },
    reportParams: {
      type: Object,
      default: () => ({})
    }
  },

  setup (props) {
    const chartRef = ref()

    // Computed properties for max size and step size of the Y chart axis
    const maxSize = computed(() => {
      if (props.reportParams.reportType === 'vehicleSensors') {
        return props.fetchedReport.chartConfig.maxValue
      }
      return props.reportParams.lowVoltageValue
    })
    const stepSize = 1

    const chartConfig = computed(() => {
      return props.fetchedReport.chartConfig
    })

    // Computed properties for min and max range dates, used to limit panning on the chart
    const minRangeDate = computed(() => {
      return parseISO(props.reportParams.dateFrom).valueOf()
    })
    const maxRangeDate = computed(() => {
      return parseISO(props.reportParams.dateTo).setHours(23, 59, 59).valueOf()
    })

    const chartData = computed(() => ({
      datasets: [
        {
          label: '',
          pointRadius: 0.1,
          fill: true,
          spanGaps: false,
          borderWidth: 2,
          tension: 0.1,
          hidden: false,
          borderColor: 'rgb(134,157,182)',
          backgroundColor: 'rgba(134,157,182,0.2)',
          data: props.fetchedReport.data.map(item => {
            const itemObject = {}

            for (const key in chartConfig.value.itemFields) {
              itemObject[key] = item[chartConfig.value.itemFields[key]]
            }

            return itemObject
          })
        }
      ]
    }))

    const chartOptions = computed(() => ({
      maintainAspectRatio: false,
      parsing: false,
      hover: {
        mode: 'nearest',
        axis: 'x',
        intersect: false
      },
      scales: {
        y: {
          stacked: false,
          beginAtZero: true,
          min: 0,
          max: maxSize.value,
          grid: {
            display: false,
            color: 'rgba(255,99,132,0.2)'
          },
          ticks: {
            color: 'black',
            stepSize: stepSize.value,
            callback: (label) => `${label} V`
          }
        },
        x: {
          type: 'time',
          min: minRangeDate.value,
          max: maxRangeDate.value,
          time: {
            displayFormats: {
              day: 'dd.MM.',
              hour: 'dd.MM. HH:mm',
              minute: 'dd.MM. HH:mm',
              second: 'HH:mm:ss'
            }
          },
          grid: {
            display: true
          },
          ticks: {
            color: 'black'
          }
        }
      },
      plugins: {
        tooltip: {
          mode: 'index',
          displayColors: false,
          intersect: false,
          backgroundColor: 'rgba(0,0,0,0.8)',
          callbacks: {
            title: function () {},
            labelTextColor: function (context) {
              return 'white'
            },
            label: function (tooltipItem) {
              return chartConfig.value.tooltipFields.map(item => {
                const append = item.append || ''
                const fallback = item.fallback || ''

                return [(tooltipItem.parsed[item.field] || fallback) + append]
              })
            }
          }
        },
        legend: {
          display: false
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
            modifierKey: 'ctrl'
          },
          zoom: {
            mode: 'x',
            wheel: {
              enabled: true,
              speed: 0.2
            },
            pinch: {
              enabled: true
            }
          },
          limits: {
            x: {
              min: minRangeDate.value,
              max: maxRangeDate.value,
              minRange: 1800000 // minimum zoom to minutes
            }
          }
        },
        decimation: {
          enabled: true
        }
      }
    }))

    return {
      chartRef,
      chartData,
      chartOptions
    }
  }
})
</script>
