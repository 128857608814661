<template>
  <v-row id="report-wrapper">
    <v-col>
      <v-card class="ma-4">
        <v-tabs
          v-model="tab"
          height="30"
          grow
        >
          <v-tab
            v-for="(data, date) in fetchedReport.data.groupedData"
            :key="'tab_' + date"
            class="py-3"
          >
            {{ date }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(data, index) in fetchedReport.data.groupedData"
            :key="'tab_item_' + index"
          >
            <v-row no-gutters>
              <v-data-table
                v-if="fetchedReport.data"
                :items="transformToArrayOfObjects(data)"
                :items-per-page="fetchedReport.tableConfig.itemsPerPage || 10"
                :footer-props="{
                  itemsPerPageOptions: config.dataTableItemsPerPageOptions
                }"
                class="elevation-1 flex"
                dense
              >
                <template #header>
                  <thead>
                    <tr>
                      <th class="text-left custom-width">
                        {{ $t('satellite-tracking/report.time') }}
                      </th>
                      <th
                        v-for="sensorName in fetchedReport.data.sensorNames"
                        :key="'header_' + sensorName"
                        class="text-left custom-width"
                      >
                        {{ sensorName }}
                      </th>
                      <th class="text-left custom-width">
                        {{ $t('satellite-tracking/report.door') }}
                      </th>
                      <th />
                    </tr>
                  </thead>
                </template>
                <template #body="{items}">
                  <tbody>
                    <tr
                      v-for="(item, key) in items"
                      :key="key"
                    >
                      <td>{{ item.time }}</td>
                      <td
                        v-for="(sensorName, sensorKey) in fetchedReport.data.sensorNames"
                        :key="'td_' + key + '_' + sensorName"
                        :class="getTextColorClassBasedOnTemperatureRange(item[sensorKey], sensorKey)"
                      >
                        {{ item[sensorKey] }}
                      </td>
                      <td
                        :class="{ 'red--text': item.door_opened && getShowReferenceValues }"
                      >
                        {{ getDoorStatus(item.door_opened) }}
                      </td>
                      <td />
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import dataTableConfig from '@/global/common/dataTableConfig'
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters: mapGettersReports
} = createNamespacedHelpers('base/reportsByVehicle')

export default {
  name: 'TemperatureReportTable',

  props: {
    fetchedReport: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data () {
    return {
      tab: null,
      config: dataTableConfig
    }
  },

  computed: {
    ...mapGettersReports(['getTemperatureRanges', 'getShowReferenceValues'])
  },

  methods: {
    transformToArrayOfObjects (data) {
      return Object.values(data)
    },

    getTextColorClassBasedOnTemperatureRange (temperature, sensorKey) {
      // Slider values are stored as [xx, yy] where xx is left slider value (min in this case)
      // and yy is right side of picker, max slider value in this case
      switch (true) {
        case !this.getShowReferenceValues:
          return 'black--text'
        case temperature > this.getTemperatureRanges[sensorKey][1]:
          return 'red--text'
        case temperature < this.getTemperatureRanges[sensorKey][0]:
          return 'blue--text'
        default:
          return 'black--text'
      }
    },

    getDoorStatus (status) {
      switch (status) {
        case true:
          return this.$t('base.open')
        case false:
          return this.$t('base.closed')
        default:
          return '-'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-tab {
  max-width: 12.5rem !important;
  font-size: 12px;
}
.custom-width {
    white-space: nowrap;
    min-width: 7%;
    width: 10%;
}
</style>
